
/*!

=========================================================
* Black Dashboard - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard
* Copyright 2019 Creative Tim (https://www.creative-tim.com)


* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

 // Core

@import "custom/functions";
@import "bootstrap/functions";

@import "custom/variables";
@import "bootstrap/variables";

@import "custom/mixins";
@import "bootstrap/mixins";

 // Bootstrap components

@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
@import "bootstrap/dropdown";
@import "bootstrap/button-group";
@import "bootstrap/input-group";
@import "bootstrap/custom-forms";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/card";
@import "bootstrap/breadcrumb";
@import "bootstrap/pagination";
@import "bootstrap/badge";
@import "bootstrap/jumbotron";
@import "bootstrap/alert";
@import "bootstrap/progress";
@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/close";
@import "bootstrap/modal";
@import "bootstrap/tooltip";
@import "bootstrap/popover";
@import "bootstrap/carousel";
@import "bootstrap/utilities";
@import "bootstrap/print";

 // Custom components

 @import "custom/alerts.scss";
 @import "custom/buttons.scss";
 @import "custom/dropdown.scss";
 @import "custom/footer.scss";
 @import "custom/forms.scss";
 @import "custom/images.scss";
 @import "custom/modal.scss";
 @import "custom/navbar.scss";
 @import "custom/type.scss";
 @import "custom/tables";
 @import "custom/checkboxes-radio";
 @import "custom/fixed-plugin";
 @import "custom/sidebar-and-main-panel.scss";
 @import "custom/misc.scss";
 @import "custom/rtl.scss";
 @import "custom/input-group.scss";


 // Vendor / Plugins

@import "custom/vendor/plugin-perfect-scrollbar.scss";
@import "custom/vendor/plugin-animate-bootstrap-notify.scss";

// light mode

@import "custom/white-content.scss";

// Cards

@import 'custom/card';
@import "custom/cards/card-chart";
@import "custom/cards/card-map";
@import "custom/cards/card-user";
@import "custom/cards/card-task";
@import "custom/cards/card-plain";
